<template>
  <div class="body">
    <div class="top-bg bg_2">
      <div class="top-cont">
        <div class="top-title">{{ title }}</div>
        <div class="top-text keith">{{ subTitle }}</div>
        <div class="downAppBtn" @click="jumpUrl">
          <span>立即入驻</span>
          <i></i>
        </div>
      </div>
    </div>
    <!--乐活通平台入驻流程-->
    <div class="process">
      <div class="gome_introduce">
        <div class="top_card">
          <h1 class="top_card-entitle">SETTLED</h1>
          <h3 class="top_card-title">乐活通平台入驻流程</h3>
          <div class="bottom_line"></div>
        </div>
        <div class="bottom-card">
          <div
            class="heart merchant-title"
            style="padding-bottom: 100px; margin-top: 51px"
          >
            <div class="flex box-card">
              <div class="item">
                <svg class="icon icon-pic" aria-hidden="true">
                  <use xlink:href="#icon-dengluxiaochengxu1"></use>
                </svg>
                <div class="name"><span>1</span>登录小程序</div>
                <div class="cont">
                  微信扫描下方二维码进入国美家“乐活通”小程序。
                </div>
              </div>
              <div class="arrow"></div>
              <div class="item">
                <svg class="icon icon-pic" aria-hidden="true">
                  <use xlink:href="#icon-tijiaoziliao1"></use>
                </svg>
                <div class="name"><span>2</span>提交资料</div>
                <div class="cont">
                  <div>
                    1）按照小程序内的要求上传相关资质照片及填写相关资料，主要包括：实名认证->填写公司信息->填写开户行信息
                  </div>
                  <div>2）提交审核</div>
                </div>
              </div>
              <div class="arrow"></div>
              <div class="item">
                <!-- <i class="iconfont icon-dengdaishenhe1 icon-pic"></i> -->
                <svg class="icon icon-pic" aria-hidden="true">
                  <use xlink:href="#icon-dengdaishenhe2"></use>
                </svg>
                <div class="name"><span>3</span>等待审核</div>
                <div class="cont">
                  <div>1）国美家工作人员将在7个工作日内反馈审核结果；</div>
                  <div>2）商家可查询入驻审核进度，及接收入驻结果的短信通知</div>
                </div>
              </div>
              <div class="arrow"></div>
              <div class="item">
                <svg class="icon icon-pic" aria-hidden="true">
                  <use xlink:href="#icon-qianshuhetong2"></use>
                </svg>
                <div class="name"><span>4</span>签署合同</div>
                <div class="cont">
                  1）待入驻资料审核通过之后，商户可登录小程序签署合同，支付入驻费，开始经营
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--期待加入-->
    <div class="bg-grey">
      <div class="gome_introduce">
        <div class="top_card">
          <h1 class="top_card-entitle">SETTLED</h1>
          <h3 class="top_card-title">期待加入</h3>
          <div class="bottom_line"></div>
        </div>
        <div class="bottom-card">
          <div
            class="heart merchant-title"
            style="padding-bottom: 100px; padding-top: 85px"
          >
            <div class="flex box-card">
              <div class="code-left downapp">
                <el-image
                  class="pic"
                  :src="require('@/assets/img/merchant_16.png')"
                  fit="fill"
                ></el-image>
                <div class="downAppBtn" @click="downloadApp">
                  <span>手机APP下载</span>
                  <i></i>
                </div>

                <!-- <div class="box" @click="downloadApp">手机app下载</div> -->
              </div>
              <div class="box-out" style="display: flex">
                <div class="code-box code-box1">
                  <div class="code-title">乐活通雇主端</div>
                  <el-image
                    class="code-pic"
                    :src="require('@/assets/img/qr/boss.jpg')"
                    fit="fill"
                  ></el-image>
                </div>
                <div class="code-box">
                  <div class="code-title">乐活通服务商端</div>
                  <el-image
                    class="code-pic"
                    :src="require('@/assets/img/qr/worker.jpg')"
                    fit="fill"
                  ></el-image>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--招商入驻指导-->
    <div class="introduce-height">
      <div class="gome_introduce">
        <div class="top_card">
          <h1 class="top_card-entitle">HELP</h1>
          <h3 class="top_card-title">更多入驻帮助</h3>
          <div class="bottom_line"></div>
        </div>
        <div class="bottom-carousel-card">
          <div
            class="heart merchant-title merchant-title1"
            style="margin-top: 79px"
          >
            <div class="steps">
              <div class="steps-title flex">
                <el-image
                  class="pic steps-title-pic"
                  :src="require('@/assets/img/merchant_10.png')"
                  fit="fill"
                ></el-image>
                <div class="steps-title-text">第一步：准备相关资料</div>
              </div>
              <div class="steps-cont">
                <div class="steps-cont-line fl"></div>
                <div class="steps-cont-card flex">
                  <div class="steps-cont-item">
                    <div class="name"><span>1</span>实名认证相关</div>
                    <div class="cont">
                      微信扫描下方二维码进入国美家“乐活通”小程序。
                    </div>
                  </div>
                  <div class="steps-cont-item">
                    <div class="name"><span>2</span>公司认证相关</div>
                    <div class="cont">
                      <div>
                        1）按照小程序内的要求上传相关资质照片及填写相关资料，主要包括：实名认证->填写公司信息->填写开户行信息
                      </div>
                      <div>
                        2）按照小程序内的要求上传相关资质照片及填写相关资料
                      </div>
                    </div>
                  </div>
                  <div class="steps-cont-item">
                    <div class="name"><span>3</span>开户行信息</div>
                    <div class="cont">
                      <div>1）国美家工作人员将在7个工作日内反馈审核结果；</div>
                      <div>
                        2）商家可查询入驻审核进度，及接收入驻结果的短信通知
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="steps">
              <div class="steps-title flex">
                <el-image
                  class="pic steps-title-pic"
                  :src="require('@/assets/img/merchant_11.png')"
                  fit="fill"
                ></el-image>
                <div class="steps-title-text">第二步：入驻申请</div>
              </div>
              <div class="steps-cont">
                <div class="steps-cont-line fl"></div>
                <div class="steps-cont-card flex">
                  <div class="steps-cont-item">
                    <div class="name"><span>1</span>打开乐活通</div>
                    <div class="cont">
                      微信扫描下方二维码进入国美家“乐活通”小程序。
                    </div>
                  </div>
                  <div class="steps-cont-item">
                    <div class="name"><span>2</span>填写资料</div>
                    <div class="cont">
                      <div>
                        1）按照小程序内的要求上传相关资质照片及填写相关资料，主要包括：实名认证->填写公司信息->填写开户行信息
                      </div>
                      <div>
                        2）按照小程序内的要求上传相关资质照片及填写相关资料
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="steps">
              <div class="steps-title flex">
                <el-image
                  class="pic steps-title-pic"
                  :src="require('@/assets/img/merchant_12.png')"
                  fit="fill"
                ></el-image>
                <div class="steps-title-text">第三步：等待招商审核</div>
              </div>
              <div class="steps-cont">
                <div class="steps-cont-line fl"></div>
                <div class="steps-cont-card flex">
                  <div class="steps-cont-item">
                    <div class="name"><span>1</span>招商审核</div>
                    <div class="cont">
                      微信扫描下方二维码进入国美家“乐活通”小程序。
                    </div>
                  </div>
                  <div class="steps-cont-item">
                    <div class="name"><span>2</span>查看审核结果</div>
                    <div class="cont">
                      <div>
                        1）按照小程序内的要求上传相关资质照片及填写相关资料，主要包括：实名认证->填写公司信息->填写开户行信息
                      </div>
                      <div>
                        2）按照小程序内的要求上传相关资质照片及填写相关资料
                      </div>
                    </div>
                  </div>
                  <div class="steps-cont-item">
                    <div class="name"><span>3</span>开户行信息</div>
                    <div class="cont">
                      <div>1）国美家工作人员将在7个工作日内反馈审核结果；</div>
                      <div>
                        2）商家可查询入驻审核进度，及接收入驻结果的短信通知
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="steps">
              <div class="steps-title flex">
                <el-image
                  class="pic steps-title-pic"
                  :src="require('@/assets/img/merchant_13.png')"
                  fit="fill"
                ></el-image>
                <div class="steps-title-text">第四步：签署合同及支付入驻费</div>
              </div>
              <div class="steps-cont">
                <div class="steps-cont-card flex">
                  <div class="steps-cont-item">
                    <div class="name"><span>1</span>签署合同</div>
                    <div class="cont">
                      微信扫描下方二维码进入国美家“乐活通”小程序。
                    </div>
                  </div>
                  <div class="steps-cont-item">
                    <div class="name"><span>2</span>支付入驻费</div>
                    <div class="cont">
                      <div>
                        1）按照小程序内的要求上传相关资质照片及填写相关资料，主要包括：实名认证->填写公司信息->填写开户行信息
                      </div>
                      <div>
                        2）按照小程序内的要求上传相关资质照片及填写相关资料
                      </div>
                    </div>
                  </div>
                  <div class="steps-cont-item">
                    <div class="name"><span>3</span>打印合同并盖章</div>
                    <div class="cont">
                      <div>1）国美家工作人员将在7个工作日内反馈审核结果；</div>
                      <div>
                        2）商家可查询入驻审核进度，及接收入驻结果的短信通知
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 入驻视频 -->
    <MerchantVideo
      :src="src"
      :dialog-visible="dialogVisible"
      @closeDialog="handleVisible"
    />
    <!-- 登录 -->
    <Login
      v-if="loginIsShow"
      :loginIsShow="loginIsShow"
      @loginVisible="loginVisible"
    />
  </div>
</template>

<script>
import MerchantVideo from "@/components/MerchantVideo.vue";
import Login from "@/components/Login.vue";
export default {
  data () {
    return {
      dialogVisible: false, //入驻视频弹出框是否显示
      src:
        "http://mp4.vjshi.com/2021-05-13/d0e55b2f8bdd2c536394ef93e1a3161a.mp4",
      loginIsShow: false, //登录弹出框是否显示
      title: "乐活通火热招募中",
      subTitle: "优秀服务商集合地，点金共赢就等你来",
    };
  },
  computed: {
    userName () {
      return this.$store.state.userName;
    },
  },
  // 注册子组件
  components: {
    MerchantVideo,
    Login,
  },
  mounted () {
    require('@/assets/img/bg_2.jpg')
    require('@/assets/img/bg_1.jpg')
  },
  methods: {
    jumpUrl () {
      if (sessionStorage.getItem('user_token')) {
        this.$router.push("/index/businessGuide")
      } else {
        this.$message.warning('请先登录');
      }
    },
    handleVisible () {
      this.dialogVisible = !this.dialogVisible;
    },
    downloadApp () {
      this.$router.push("/index/downloadApp");
    },
    // 登录弹框事件
    loginVisible (status) {
      this.loginIsShow = status;
    },
  },
};
</script>

<style scoped lang="scss">
$font-family: Arial-BoldItalicMT, Arial, 'Microsoft YaHei', '微软雅黑',
  Helvetica, sans-serif;

@font-face {
  font-family: Arial-BoldItalicMT;
  src: url('~@/assets/font/ArialBoldItalic.ttf') format('ttf');
}
.bg-grey {
  padding: 0;
  padding-top: 0;
}
.bg-grey1 {
  box-sizing: border-box;
  height: 548px;
  width: 100%;
  padding: 0;
  padding-top: 100px;
  background-color: rgba(30, 34, 44, 0.61);

  .box {
    overflow: hidden;

    height: 348px;

    // cursor: pointer;
    .hiddenOverflow {
      height: 400px;
      overflow-x: scroll;
    }
    &::-webkit-scrollbar {
      height: 12px !important;
      width: 0 !important;
    }
    &:hover {
      overflow-x: auto;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 6px;
      background-image: linear-gradient(
        90deg,
        rgba(0, 0, 0, 0.3),
        rgba(0, 0, 0, 0.6)
      );
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.4);
      background: rgba(0, 0, 0, 0.2);
    }

    .brand_list1 {
      margin-left: -100%;
      display: flex;
      margin-bottom: 60px;
    }
    .brand_list2 {
      margin-left: -100%;
      display: flex;
      padding-left: 180px;
    }
  }

  img {
    width: 360px;
    height: 144px;
    margin-right: 60px;
  }
}
.steps-cont-card {
  .item {
    padding: 5px 0 !important;
  }
}
.merchant-title1 {
  padding-bottom: 36px !important;
}
.top-bg {
  &.bg_2 {
    height: 800px;
    background: url('~@/assets/img/bg_2.jpg') no-repeat center 100%;
  }
  .top-text {
    width: 100%;
    height: 37px;
    text-align: center;
    margin: 26px 0;
  }
  .top-btn {
    line-height: 1;
    font-size: 20px;
  }
  //解决大标题位置问题
  /deep/.top-cont {
    width: 1200px;
    height: 800px;
    margin: auto;
    padding-top: 323px;
    box-sizing: border-box;
    text-align: center;
  }
  /deep/.top-title {
    text-align: center;
    height: 98px;
    font-size: 70px;
    font-weight: 400;
    color: #ffffff;
    line-height: 98px;
    letter-spacing: 15px;
    text-shadow: 0px 30px 40px rgba(18, 20, 31, 0.5);
  }
}
/deep/.section-btn {
  margin-top: -90px;
}
/deep/.btn-grey:hover {
  background: linear-gradient(270deg, #ff8600 0%, #ff4a2d 100%);
  color: #fff;
}
/deep/.tag-title::after {
  top: 48px;
}
.downapp {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.downAppBtn {
  width: 280px;
  height: 59px;
  background: linear-gradient(270deg, #ff8600 0%, #ff4a2d 100%);
  box-shadow: 0px 10px 15px 0px rgba(255, 84, 37, 0.3);
  border-radius: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 20px;
}
.downAppBtn:hover {
  cursor: pointer;
}
.downAppBtn > i {
  display: block;
  width: 12px;
  height: 12px;
  box-sizing: border-box;
  border: 2px solid #ffffff;
  border-left: 0;
  border-bottom: 0;
  transform: rotate(45deg);
  margin-left: 10px;
}
//头部样式
.gome_introduce {
  width: 1200px;
  margin: auto;
  position: relative;
  .top_card {
    padding-top: 60px;
    height: 123px;
    opacity: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
    .top_card-entitle {
      height: 94px;
      font-size: 78px;
      font-family: Helvetica-Bold, Helvetica;
      font-weight: bold;
      color: rgba(31, 35, 41, 0.08);
      line-height: 94px;
    }
    .top_card-title {
      margin-top: -29px;
      font-size: 40px;
      font-family: HiraginoSansGB-W6, HiraginoSansGB;
      font-weight: normal;
      line-height: 40px;
      color: #1f2329;
    }
    .bottom_line {
      width: 60px;
      height: 8px;
      background: linear-gradient(90deg, #f6ba4f 0%, #ea8427 100%);
      border-radius: 4px;
      margin-top: 10px;
    }
  }
  .bottom_card {
    display: flex;
    align-items: flex-start;
    .bottom-left-card {
      margin-right: 242px;
    }
    .bottom-left-card1 {
      margin-right: 52px;
    }
    .bottom-left-card-title {
      width: 210px;
      font-size: 30px;
      font-family: HiraginoSansGB-W6, HiraginoSansGB;
      font-weight: normal;
      color: #1f2329;
      line-height: 30px;
      margin-top: 80px;
    }
    .bottom-left-card-line {
      width: 40px;
      height: 8px;
      background: linear-gradient(90deg, #f6ba4f 0%, #ea8427 100%);
      border-radius: 4px;
      margin-top: 10px;
    }
    .bottom-left-card-content {
      width: 601px;
      height: 223px;
      font-size: 20px;
      // font-family: MicrosoftYaHei;
      color: #666666;
      line-height: 40px;
      margin-top: 22px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .bottom-left-card-introduce {
      width: 549px;
      height: 380px;
      font-size: 20px;
      // font-family: MicrosoftYaHei;
      color: #666666;
      line-height: 40px;
      margin-top: 22px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .bottom-right-card {
      margin-top: 33px;
    }
    .bottom-right-card1 {
      margin-top: 80px;
    }
    .bottom-right-card-img {
      width: 228px;
      height: 494px;
    }
    .bottom-right-card-photo {
      width: 600px;
      height: 450px;
    }
  }
}
//步骤条
.introduce-height {
  height: 2620px;
}
.merchant-title {
  .title-box {
    justify-content: space-between;
    margin-bottom: 40px;
  }

  .title-box1 {
    margin-bottom: 52px;
  }

  .title-box2 {
    margin-bottom: 34px;
  }

  .title-box3 {
    margin-bottom: 26px;
  }

  .box-card {
    justify-content: space-between;

    .item {
      background: #ffffff;
      box-shadow: 0 30px 50px 0 rgba(206, 206, 206, 0.3);
      border-radius: 26px;
      width: 257px;
      height: 420px;
      box-sizing: border-box;
      padding: 43px 18px 43px 21px;
      text-align: center;

      .icon-pic {
        width: 74px;
        height: 74px;
        margin: 0 auto;
      }

      .name {
        margin: 29px 0 20px;
        font-size: 32px;
        color: #4a4a4a;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          font-size: 50px;
          font-family: $font-family;
          margin-right: 5px;
        }
      }

      .cont {
        text-align: left;
        font-size: 16px;
        color: #999999;
        line-height: 28px;
      }
    }

    .arrow {
      width: 30px;
      height: 30px;
      border-right: 3px solid #999999;
      border-bottom: 3px solid #999999;
      border-top: 3px solid transparent;
      border-left: 3px solid transparent;
      transform: translateX(-12px) rotate(-45deg);
    }

    .code-left {
      width: 520px;
      height: 400px;
      position: relative;
      left: -40px;
    }

    .code-box {
      width: 300px;
      height: 400px;
      text-align: center;
      background-image: url('~@/assets/img/card_bg.png');
      background-position: right;
      box-shadow: 0 30px 50px 0 rgba(206, 206, 206, 0.3);
      border-radius: 26px;

      .code-title {
        width: 207px;
        height: 45px;
        background: url('~@/assets/img/merchant_9.png') no-repeat center 0;
        font-size: 16px;
        color: #ffffff;
        display: inline-block;
        position: relative;
        top: -7px;
        line-height: 45px;
      }

      .code-pic {
        width: 160px;
        height: 160px;
        margin: 70px auto 25px;
      }

      .code-text {
        font-size: 12px;
        color: #999999;
      }
    }

    .code-box1 {
      margin-right: 50px;
    }
  }

  .steps {
    clear: both;

    .steps-title {
      justify-content: flex-start;
      margin-bottom: 22px;

      .steps-title-pic {
        width: 74px;
        height: 74px;
        margin-right: 45px;
      }

      .steps-title-text {
        width: 448px;
        height: 42px;
        font-size: 32px;
        // font-family: MicrosoftYaHei;
        color: #4a4a4a;
        line-height: 42px;
      }
    }

    .steps-cont {
      .steps-cont-line {
        height: 470px;
        border-left: 2px dashed #979797;
        position: relative;
        left: 37px;

        &::before {
          content: '';
          width: 10px;
          border-top: 2px dashed #979797;
          position: absolute;
          left: -7px;
          top: 0;
        }

        &::after {
          content: '';
          width: 10px;
          border-bottom: 2px dashed #979797;
          position: absolute;
          left: -7px;
          bottom: 0;
        }
      }

      .steps-cont-card {
        justify-content: flex-start;
        margin-left: 123px;
        margin-top: 12px;

        .steps-cont-item {
          &:last-child {
            margin-right: 0;
          }

          .name {
            padding-top: 55px;
            margin-bottom: 20px;
            font-size: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 42px;
            // font-family: MicrosoftYaHei;
            color: #4a4a4a;
            line-height: 42px;

            span {
              font-weight: normal;
              line-height: 58px;
              font-size: 50px;
              font-family: $font-family;
              margin-right: 5px;
            }
          }

          .cont {
            padding: 0 48px 0 54px;
            text-align: left;
            font-size: 16px;
            color: #999999;
            line-height: 28px;
          }
        }
      }
    }
  }

  .pic-card {
    justify-content: flex-start;
    flex-wrap: wrap;

    .pic-card-item {
      width: 200px;
      justify-content: flex-start;
      margin: 55px 0;

      .pic-card-icon {
        width: 60px;
        height: 54px;
        margin-right: 7px;
        color: darkorange;
      }

      .pic-card-text {
        font-size: 16px;
        color: #333333;
        text-align: center;
        min-width: 80px;
      }
    }
  }
}
.steps-cont-item {
  width: 320px;
  height: 400px;
  border-radius: 20px;
  background: url('~@/assets/img/stores_bg3.png') no-repeat center 0;
  // padding-top: 55px;
  text-align: center;
  margin-right: 60px;
}
.process {
  height: 800px;
}
.downAppBtn {
  width: 280px;
  height: 59px;
  background: linear-gradient(270deg, #ff8600 0%, #ff4a2d 100%);
  box-shadow: 0px 10px 15px 0px rgba(255, 84, 37, 0.3);
  border-radius: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 20px;
  margin: 80px auto 0 auto;
}
.downAppBtn:hover {
  cursor: pointer;
}
.downAppBtn > i {
  display: block;
  width: 12px;
  height: 12px;
  box-sizing: border-box;
  border: 2px solid #ffffff;
  border-left: 0;
  border-bottom: 0;
  transform: rotate(45deg);
  margin-left: 10px;
}
</style>
